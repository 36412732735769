<template>
  <el-row :gutter="10" class="tabs">
    <el-col :span="17">
      <div class="boardBox">
        <div class="title">气源供气情况</div>
        <div class="dataBox">
          <div class="dataDom">
            <div class="val">
              {{screen3.yearNum}}
              <span>万Nm³</span>
            </div>
            <div class="info">本年度气量</div>
          </div>
          <div class="dataDom">
            <div class="val">
              {{screen3.quarterNum}}
              <span>万Nm³</span>
            </div>
            <div class="info">本季度气量</div>
          </div>
          <div class="dataDom">
            <div class="val">
              {{screen3.monthNum}}
              <span>万Nm³</span>
            </div>
            <div class="info">本月气量</div>
          </div>
          <div class="dataDom">
            <div class="val">
              {{screen3.weekNum}}
              <span>万Nm³</span>
            </div>
            <div class="info">本周气量</div>
          </div>
          <div class="dataDom">
            <div class="val">
              {{screen3.dayNum}}
              <span>万Nm³</span>
            </div>
            <div class="info">本日气量</div>
          </div>
        </div>
      </div>

      <div class="boardBox">
        <div class="title">重点监测用户站用气量情况</div>
        <div class="dataBox">
          <div class="dataDom Monit">
            <div class="val">
              {{screen4.yearNum}}
              <span>万Nm³</span>
            </div>
            <div class="info">本年度气量</div>
          </div>
          <div class="dataDom Monit">
            <div class="val">
              {{screen4.quarterNum}}
              <span>万Nm³</span>
            </div>
            <div class="info">本季度气量</div>
          </div>
          <div class="dataDom Monit">
            <div class="val">
              {{screen4.monthNum}}
              <span>万Nm³</span>
            </div>
            <div class="info">本月气量</div>
          </div>
          <div class="dataDom Monit">
            <div class="val">
              {{screen4.weekNum}}
              <span>万Nm³</span>
            </div>
            <div class="info">本周气量</div>
          </div>
          <div class="dataDom Monit">
            <div class="val">
              {{screen4.dayNum}}
              <span>万Nm³</span>
            </div>
            <div class="info">本日气量</div>
          </div>
        </div>
      </div>

      <div class="gasTrend">
        <div class="title">近30日供气趋势</div>
        <div class="echartsBox" ref="echartsBox"></div>
      </div>
    </el-col>

    <el-col :span="7">
      <div class="tableBox" style="margin-bottom: 10px">
        <div class="title">用户站最低压力监测</div>
        <div class="tableDom">
          <el-table :data="tableDatalow" border height="100%">
            <el-table-column
              prop="stationName"
              label="调压箱(柜)"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              width="120"
              prop="val"
              label="进口压力"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                {{row.val}}{{row.unit}}
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              prop="uploadTime"
              label="上报时间"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.uploadTime"
                  placement="top"
                >
                  <span>{{ row.uploadTime.slice(11) }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="tableBox">
        <div class="title">用户站最高压力监测</div>
        <div class="tableDom">
          <el-table :data="tableDatahight" border height="100%">
            <el-table-column
              prop="stationName"
              label="调压箱(柜)"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              width="120"
              prop="val"
              label="进口压力"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                {{row.val}}{{row.unit}}
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              prop="uploadTime"
              label="上报时间"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.uploadTime"
                  placement="top"
                >
                  <span>{{ row.uploadTime.slice(11) }}</span>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import scadaApi from "@/apis/scadaApi";
export default {
  name: "dataBoard",
  components: {},
  data() {
    return {
      tableDatalow: [],
      tableDatahight: [],
      echartsBox:null,
      screen4:{},
      screen3:{}
    }
  },
  mounted(){
    this.echartsBox = this.$echarts.init(this.$refs.echartsBox)
    this.getAllData()
  },
  methods: {
    getAllData(){

      // 用户站最低压力监测
      scadaApi.pressureMonitoring({type:1}).then(res => {
        this.tableDatalow = res.data
      })

      // 用户站最高压力监测
      scadaApi.pressureMonitoring({type:2}).then(res => {
        this.tableDatahight = res.data
      })

      // 近三十天
      scadaApi.gasSupplyTrend().then(res => {
        this.trend(res.data)
      })

      // 重点监测用户站用气量情况
      scadaApi.screen4().then(res => {
        this.screen4 = res.data
      })

      // 气源供气情况
      scadaApi.screen3().then(res => {
        this.screen3 = res.data
      })

    },
    trend(allData) {
      let option = {
        color: ["#1082FF", "#01B3E4", "#FF7E00", "#99004c"],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          borderColor: "rgba(147, 235, 248, .8)",
          textStyle: {
            color: "#FFF",
          },
        },
        // dataZoom: [
        //   {
        //     type: "slider",
        //     start: 0,
        //     end: 30,
        //     bottom: 40
        //   },
        // ],
        grid: {
          left: "3%",
          right: "3%",
          bottom: "40px",
          top: "40px",
          containLabel: true,
        },

        // legend: {
        //   show: true,
        //   icon: "rect",
        //   orient: "horizontal",
        //   left: "right",
        //   itemWidth: 12,
        //   itemHeight: 12,
        //   formatter: ["{a|{name}}"].join("\n"),
        //   textStyle: {
        //     fontSize: 12,
        //     color: "#6A93B9",
        //     height: 8,
        //     rich: {
        //       a: {
        //         verticalAlign: "bottom",
        //       },
        //     },
        //   },
        //   data: [data.type],
        // },
        xAxis: {
          // type: 'time',
          // max: data.endTime,
          // min: data.startTime,
          // boundaryGap: false,
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#D8DEE3",
              fontSize: 12,
            },
          },
          axisLabel: {
            // interval:0,
            color: "#7E8D9A",
            fontSize: 12,
          },
          axisTick: {
            show: true,
          },
          data: allData.xAxis['data'],
        },
        yAxis: {
          // name: "单位(" + data.unit + ")",
          // name: {
          //   color: "#7E8D9A",
          //   fontSize: 12,
          // },
          type: "value",
          minInterval: 1,
          splitArea: { show: false },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 12,
            fontFamily: "Bebas",
            color: "#7E8D9A",
          },
        },
        series: [
          {
            type: "line",
            smooth: true, // 是否曲线
            // name: data.type, // 图例对应类别
            data: allData.series[0]['data'], // 纵坐标数据
            areaStyle: {
              color: {
                type: "linear",
                x: 0, //右
                y: 0, //下
                x2: 0, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 0.3,
                    color: "#188FFE", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#F0FBF8", // 100% 处的颜色
                  },
                ],
              },
            },
          },
        ],
      };
      this.echartsBox.setOption(option, true);
    },
  },
};
</script>

<style scoped lang="scss">
.tabs {
  margin: 10px 5px !important;
  height: calc(100vh - 173px);
  .title {
    height: 44px;
    line-height: 44px;
    padding: 0 20px;
    border-bottom: 1px solid #ebebeb;
    color: #0f0f0f;
    font-weight: bold;
    font-size: 14px;
  }
  .boardBox {
    height: 200px;
    background-color: #fff;
    margin-bottom: 10px;

    .dataBox {
      padding: 20px;
      .dataDom {
        display: inline-block;
        width: calc(20% - 15px);
        margin-right: 16px;
        height: 115px;
        border: 1px solid #ebeef5;
        border-radius: 4px;
        background: #f9fcff;
        vertical-align: top;
        padding: 26px 0 26px 20px;
        box-sizing: border-box;
        .val {
          color: #333;
          margin-bottom: 8px;
          font-size: 26px;
          font-weight: bold;
          height: 26px;
          line-height: 26px;
          span {
            font-weight: normal;
          }
        }
        .info {
          color: #606266;
          font-size: 16px;
        }
      }
      .dataDom.Monit {
        background: #f9fffc;
      }
      .dataDom:last-child {
        margin-right: 0;
      }
    }
  }
  .gasTrend {
    height: calc(100vh - 592px);
    background-color: #fff;
  }
  .echartsBox {
    height: calc(100% - 45px);
  }
  .tableBox {
    height: calc((100vh - 182px) / 2);
    background: #fff;
    .tableDom {
      padding: 20px;
      height: calc(100% - 85px);
    }
  }
}
</style>